.chat-container {
  display: flex;
  flex-direction: row;
  height: 87vh;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar {
  border-right: 1px solid #ddd;
  padding: 10px;
  background-color: #f7f7f7;
  overflow-y: auto;
  width: 400px;
}

/* Mobile-friendly adjustments */
@media (max-width: 768px) {
  .chat-container {
    flex-direction: column;
    height: 80vh;
  }

  .sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
  .chat-header2 {
    display: none;
  }
}

.chat-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.chat-header {
  padding: 15px;
  background-color: #075e54;
  color: white;
  font-weight: bold;
  text-align: center;
}

.chat-header2 {
  padding: 15px;
  background-color: #075e54;
  color: white;
  font-weight: bold;
  text-align: center;
}

.chat-messages {
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 380px;
  overflow-y: auto;
  padding-bottom: 10px;
}

.message {
  display: flex;
  align-items: flex-start;
  max-width: 70%;
  padding: 10px;
  border-radius: 8px;
  word-wrap: break-word;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

/* Message responsive fix */
@media (max-width: 600px) {
  .message {
    max-width: 90%;
  }
}

.student-message {
  align-self: flex-start;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.user-message {
  align-self: flex-end;
  background-color: #dcf8c6;
}

.profile-container {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.message-content {
  display: flex;
  flex-direction: column;
}

.no-messages {
  text-align: center;
  font-size: 16px;
  color: #555;
  font-weight: bold;
  margin-top: 30px;
  padding: 10px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.8s ease-in-out;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  color: #555;
  text-align: center;
  animation: fadeIn 1.5s infinite alternate;
}

/* Responsive fix for loading */
@media (max-width: 600px) {
  .loading-container {
    font-size: 16px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f7f7f7;
  align-items: center;
}

/* Responsive chat input */
@media (max-width: 600px) {
  .chat-input {
    flex-direction: column;
    gap: 10px;
  }

  .chat-input input {
    width: 100%;
  }

  .chat-input button {
    width: 100%;
  }
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.chat-input button {
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #075e54;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.doc-file {
  margin-top: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  background-color: #f8f8f8;
  width: 270px;
}

.doc-file-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.selected-file {
  position: absolute;
  bottom: 50px;
  left: 40px;
  margin-bottom: 10px;
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f5f5f5;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.selected-file-name {
  font-weight: bold;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Responsive button */
@media (max-width: 600px) {
  .chat-input button {
    margin-left: 0;
  }
}

.chat-button {
  background-color: #d9d9d9;
  color: black;
  border: transparent;
  height: 30px;
  flex: 1 1 auto;
}

.chat-button.active {
  background-color: #075e54;
  color: white;
}

/* Mobile View - 2 Buttons in Row, 1 Below */
@media (max-width: 600px) {
  .chat-button {
    flex: 1 1 45%; /* 2 buttons ek row mai */
  }

  .chat-button:last-child {
    flex: 1 1 100%;
  }
}

/* Desktop View - All in One Row */
@media (min-width: 601px) {
  .chat-button {
    flex: 1 1 auto;
  }
}
